var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-form',{staticClass:"register-form"},[_c('div',{staticStyle:{"font-size":"12px"}},[_c('p',[_c('span',[_vm._v("客服微信：")]),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v(_vm._s(_vm.setting.wechatId))]),_c('span',[_vm._v("，有任何问题，请联系客服微信")])]),_c('p',{staticStyle:{"margin-top":"10px","color":"#f00"}},[_c('span',[_vm._v("邀请玩家，可以获取大量积分奖励，奖励规则")]),_c('router-link',{attrs:{"to":{ name: 'InvitationDesc' }}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("进入查看")])])],1),_c('p',{staticStyle:{"margin-top":"10px","color":"#f00"}},[_c('span',[_vm._v("第一天充值500元的号，如何快速到达70级")]),_c('router-link',{attrs:{"to":{ name: 'Gl' }}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("进入查看")])])],1)]),_c('div',{staticClass:"hr"}),_c('div',{staticClass:"hr"}),_c('van-field',{attrs:{"name":"account","label":"账号","placeholder":"请输入账号","rules":[
      { required: true, message: '必须输入账号' },
      { pattern: /^.{8,16}$/, message: '账号必须是8-16位之间' }
    ],"clearable":""},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}}),_c('van-field',{attrs:{"name":"password","label":"密码","placeholder":"请输入密码","type":"password","rules":[
      { pattern: /^[A-Z]/, message: '第一个字符必须是大写字母' },
      { pattern: /^.[a-z]/, message: '第二个字符必须是小写字母' },
      {
        pattern: /^(?![a-zA-Z\\_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\_!@#$%^&*`~()-+=]+$)(?![A-Z0-9\\_!@#$%^&*`~()-+=]+$)/,
        message: '数字、大小写字母三种都必须有'
      },
      { pattern: /^.{8,16}$/, message: '长度必须在8-16个字符之间' }
    ],"clearable":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"name":"repassword","label":"确认密码","type":"password","placeholder":"请再次输入密码","rules":[
      { validator: _vm.confirmPasswordValidator, message: '两次密码不一致' }
    ],"clearable":""},model:{value:(_vm.repassword),callback:function ($$v) {_vm.repassword=$$v},expression:"repassword"}}),_c('van-field',{attrs:{"name":"captcha","placeholder":"请输入验证码","type":"number","label":"验证码","rules":[
      { required: true, message: '请输入验证码' },
      { pattern: /^.{4}$/, message: '验证码长度不符' }
    ],"clearable":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-image',{attrs:{"src":_vm.captchaImg,"width":"90px","height":"30px"},on:{"click":_vm.changeCaptchaImg}})]},proxy:true}]),model:{value:(_vm.captcha),callback:function ($$v) {_vm.captcha=$$v},expression:"captcha"}}),_c('div',{staticClass:"hr"}),_c('div',[_c('p',{staticStyle:{"color":"#f00","margin-top":"10px","font-size":"12px"}},[_vm._v(" *密码必须包含大小写字母及数字三种，且首字必须是大写字母，并大于8位，如Aa123456、Bb123456等等 ")])]),_c('div',{staticClass:"hr"}),_c('van-button',{staticStyle:{"margin-top":"10px"},attrs:{"size":"large","color":"linear-gradient(to right, #ff6034, #ee0a24)"},on:{"click":_vm.registerHandle}},[_vm._v("立即注册")]),_c('van-button',{staticStyle:{"margin-top":"10px"},attrs:{"size":"large","block":"","type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'TlbbPay' })}}},[_vm._v("前往充值")]),_c('van-button',{staticStyle:{"margin-top":"10px"},attrs:{"size":"large","block":"","type":"info","icon":"down"},on:{"click":_vm.downloadHandle}},[_vm._v("游戏下载")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }