<template>
  <van-form class="register-form">
    <div style="font-size: 12px">
      <p>
        <span>客服微信：</span>
        <span style="color: #f00">{{ setting.wechatId }}</span>
        <span>，有任何问题，请联系客服微信</span>
      </p>
      <p style="margin-top: 10px;color: #f00">
        <span>邀请玩家，可以获取大量积分奖励，奖励规则</span>
        <router-link :to="{ name: 'InvitationDesc' }"
          ><span style="text-decoration: underline;"
            >进入查看</span
          ></router-link
        >
      </p>
      <p style="margin-top: 10px;color: #f00">
        <span>第一天充值500元的号，如何快速到达70级</span>
        <router-link :to="{ name: 'Gl' }"
          ><span style="text-decoration: underline;"
            >进入查看</span
          ></router-link
        >
      </p>
    </div>
    <div class="hr"></div>
    <div class="hr"></div>
    <van-field
      v-model="account"
      name="account"
      label="账号"
      placeholder="请输入账号"
      :rules="[
        { required: true, message: '必须输入账号' },
        { pattern: /^.{8,16}$/, message: '账号必须是8-16位之间' }
      ]"
      clearable
    ></van-field>
    <van-field
      v-model="password"
      name="password"
      label="密码"
      placeholder="请输入密码"
      type="password"
      :rules="[
        { pattern: /^[A-Z]/, message: '第一个字符必须是大写字母' },
        { pattern: /^.[a-z]/, message: '第二个字符必须是小写字母' },
        {
          pattern: /^(?![a-zA-Z\\_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\_!@#$%^&*`~()-+=]+$)(?![A-Z0-9\\_!@#$%^&*`~()-+=]+$)/,
          message: '数字、大小写字母三种都必须有'
        },
        { pattern: /^.{8,16}$/, message: '长度必须在8-16个字符之间' }
      ]"
      clearable
    ></van-field>
    <van-field
      v-model="repassword"
      name="repassword"
      label="确认密码"
      type="password"
      placeholder="请再次输入密码"
      :rules="[
        { validator: confirmPasswordValidator, message: '两次密码不一致' }
      ]"
      clearable
    ></van-field>
    <van-field
      name="captcha"
      v-model="captcha"
      placeholder="请输入验证码"
      type="number"
      label="验证码"
      :rules="[
        { required: true, message: '请输入验证码' },
        { pattern: /^.{4}$/, message: '验证码长度不符' }
      ]"
      clearable
    >
      <template #button>
        <van-image
          :src="captchaImg"
          width="90px"
          height="30px"
          @click="changeCaptchaImg"
        ></van-image>
      </template>
    </van-field>
    <div class="hr"></div>
    <div>
      <p style="color: #f00;margin-top: 10px;font-size: 12px">
        *密码必须包含大小写字母及数字三种，且首字必须是大写字母，并大于8位，如Aa123456、Bb123456等等
      </p>
    </div>
    <div class="hr"></div>
    <van-button
      size="large"
      style="margin-top: 10px"
      color="linear-gradient(to right, #ff6034, #ee0a24)"
      @click="registerHandle"
      >立即注册</van-button
    >
    <van-button
      size="large"
      block
      type="primary"
      style="margin-top: 10px"
      @click="$router.push({ name: 'TlbbPay' })"
      >前往充值</van-button
    >
    <van-button
      size="large"
      block
      type="info"
      style="margin-top: 10px"
      icon="down"
      @click="downloadHandle"
      >游戏下载</van-button
    >
  </van-form>
</template>
<script>
import { Form, Field, Button, Image, Toast, Notify } from "vant";
import { mapActions, mapState } from "vuex";

export default {
  name: "TlbbRegister",
  components: {
    "van-form": Form,
    "van-field": Field,
    "van-button": Button,
    "van-image": Image
  },
  data() {
    return {
      account: "",
      password: "",
      repassword: "",
      captcha: "",
      captchaImg: ""
    };
  },
  metaInfo() {
    return {
      title: "注册账号"
    };
  },
  mounted() {
    this.changeCaptchaImg();
  },
  computed: {
    ...mapState({ setting: state => state.setting.setting })
  },
  methods: {
    ...mapActions("register", ["register"]),
    confirmPasswordValidator(val) {
      return val === this.password;
    },
    changeCaptchaImg() {
      let url = `${
        process.env.NODE_ENV === "development" ? "http://localhost:7777" : ""
      }/api/tlbb/capcha`;
      this.captchaImg = url + "?t=" + Date.now();
      this.captcha = "";
    },
    initData() {
      this.account = "";
      this.password = "";
      this.repassword = "";
      this.captcha = "";
    },
    registerHandle() {
      let parentAccount = window.localStorage.getItem("parent_account");
      let params = {
        agent: 4193,
        account: this.account,
        password: this.password,
        repassword: this.repassword,
        captcha: this.captcha,
        u: parentAccount
      };

      let loading = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });

      this.register(params)
        .then(() => {
          loading.clear();
          Notify({ type: "success", message: "注册成功" });
          window.localStorage.setItem("account", this.account);
          this.changeCaptchaImg();
          this.initData();
        })
        .catch(err => {
          Notify({ type: "danger", message: err.msg });
          this.changeCaptchaImg();
          loading.clear();
        });
    },
    downloadHandle() {
      if (!this.setting.downloadBySelf) {
        if (this.setting.downloadUrl) {
          window.location.href = this.setting.downloadUrl;
        } else {
          this.$dialog({
            title: "提示",
            message: "请配置下载地址"
          });
        }
      } else {
        this.$router.push({ name: "TlbbDownload" });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.register-form {
  width: 80%;
  margin: 10px auto;
}
</style>
